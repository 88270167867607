:root {
    --color-accent: #eddadd;
    --color-main: #0fa3b1;
    --color-dark: #2f4858;
    --gradient-top: #fde0df;
    --gradient-bottom: #c5e8f4;
}

html,
body {
    height: 100%;
    font-size: 20px;
    font-weight: 500;
}

.page-wrap {
    min-height: 100%;
}
.page-wrap:after {
    content: "";
    display: block;
}
.site-footer {
    background-color: #9bd4d7;
}
.site-footer,
.page-wrap:after {
    width: 100%;
}
.site-footer-content {
    flex-direction: row;
    width: 100%;
    max-width: 1632px;
    min-height: 64px;
}
@media screen and (max-width: 540px) {
    .site-footer-content {
        flex-direction: column;
    }
}
@media screen and (min-width: 577px) {
    .site-footer-content {
        padding: 0 1em;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bg-hh-accent {
    background-color: var(--color-accent);
}

.bg-hh-gradient {
    /* background: var(--gradient-top);
  background: linear-gradient(from top, var(--gradient-top), var(--gradient-bottom)); */
    background: rgb(253, 224, 223);
    background: linear-gradient(180deg, rgba(253, 224, 223, 1) 0%, rgba(197, 232, 244, 1) 100%);
    height: max-content;
}

.foreground {
    position: relative;
    z-index: 1;
}

.color-dark {
    color: var(--color-dark);
}
.color-main {
    color: var(--color-main);
}

.text-right {
    text-align: right;
}

h1 {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: var(--color-main);
}

h2 {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: var(--color-main);
}

h3 {
    font-size: 2.25em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: var(--color-main);
}

h4,
h5,
h6,
p {
    color: var(--color-dark);
}

@media screen and (max-width: 770px) {
    h1 {
        font-size: 6vw;
    }
    h2 {
        font-size: 5.5vw;
    }
    h3 {
        font-size: 4vw;
    }
    h4,
    h5,
    h6,
    p {
        font-size: 3.5vw;
    }
}

a,
a.active,
.social-icon {
    color: var(--color-dark) !important;
    transition: 0.2s;
}
a:hover,
a:focus,
.social-icon:hover,
.social-icon:focus,
#footer .social-icon:hover,
#footer .social-icon:focus {
    color: var(--color-main) !important;
    cursor: pointer;
    transition: 0.3s;
}
#footer .social-icon,
#footer p {
    color: white !important;
    transition: 0.2s;
    font-weight: bold;
}

#footer a {
    color: white !important;
    transition: 0.2s;
    font-weight: bold;
}

.btn {
    border-radius: 2em;
    padding: 0.3em 1.8em;
    margin: 0.3em 0.6em;
}
.btn,
.btn:hover,
.btn:focus,
.btn:active {
    background-color: var(--color-dark);
    border-color: var(--color-dark);
}

.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.accordion-item {
    border: 1px solid var(--color-main) !important;
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}
.accordion-item:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: box-shadow 0.3s ease-in-out;
}
.accordion-item,
.accordion-button {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
}
.accordion-button {
    background-color: transparent;
}
.accordion-button:not(.collapsed) {
    color: var(--color-dark);
    background-color: rgba(241, 253, 254, 0.9);
}
.accordion-button:not(.collapsed)::after {
    /* I copy-pasted the expanded background image and changed the fill color */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232f4858'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.page-link {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #0fa3b1;
}

.page-link:hover {
    background: rgba(10, 105, 114, 1);
    border: 1px solid #0fa3b1;
}

.page-item.active .page-link {
    background-color: #0fa3b1;
    border-color: #0fa3b1;
}

.nav-link {
    font-size: 0.92rem;
    font-weight: 600;
}

.footer-link {
    text-decoration: none;
}
.footer-link:hover {
    text-decoration: underline;
}
.footer-copyright {
    font-size: 0.86rem;
}

/* Using Grid display for the "Our Sponsors" title/subtitle with the bird. Because I wanted to leard grid layout :)*/

.our-sponsors {
    width: 75%;
    margin-left: auto;
    margin-right: auto;

    display: grid;
    grid-template-rows: 0.2fr 1.8fr;
    gap: 10px 10px;
}

.sponsors-bird {
    width: 100%;
    justify-self: center;
}
.sponsors-title {
    grid-area: 1 / 1 / 1 / 1;
}
.sponsors-subtitle {
    grid-area: 2 / 1 / 2 / 1;
}

@media (min-width: 770px) {
    .our-sponsors {
        grid-template-columns: 1.5fr 0.5fr;
    }

    .sponsors-bird {
        grid-area: 1 / 2 / 3 / 3;
    }
}

@media (max-width: 770px) {
    .our-sponsors {
        width: 75%;
        margin-left: auto;
        margin-right: auto;

        display: grid;
        grid-template-columns: 1fr;
    }

    .sponsors-bird {
        width: 100%;
        max-width: 250px;
    }
}
